import React, { useState, useEffect, useRef } from "react"
import TextField from "."

const languages = ["en", "hu"]

function MultiLang(props) {
  const { name, value, handleBlur, type } = props

  const localValueRef = useRef(null)
  const [localValue, setLocalValue] = useState(null)

  const handleChange = (e) => {
    let data = e
    data = { ...localValue, [e.target.name]: e.target.value }
    localValueRef.current = data
    setLocalValue({ ...data })
  }

  const onBlur = () => {
    let data = {
      target: {
        name,
        value: localValueRef.current,
      },
    }
    console.log("this was called, blur")
    if (typeof handleBlur === "function") handleBlur(data)
  }

  useEffect(() => {
    setLocalValue(value)
    localValueRef.current = localValue
    // eslint-disable-next-line
  }, [value])

  if (localValue == null) return null

  return (
    <div className="multilang-modal">
      {languages.map((lang, index) => (
        <TextField key={index} label={lang} name={lang} onChange={handleChange} onBlur={onBlur} value={localValue[lang]} type={type != null ? type : "text"} fullWidth />
      ))}
    </div>
  )
}

export default MultiLang
