import { useContext } from "react"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/modalContext"
import useMultilang from "intl/useMultilang"
import IPanelV2Popup from "components/IPanelV2Popup"
import "./style.scss"

export default function IPanelV2Ad({ className }) {
  const { getT } = useMultilang()
  const { addModal, popModal } = useContext(ModalContext)

  const handleOnClick = () => {
    addModal(
      <Modal key={uuidv4()} onClickLayout={popModal}>
        <IPanelV2Popup />
      </Modal>
    )
  }

  return (
    <div className={`iPanelV2Ad-root ${className ? className : ""}`} onClick={handleOnClick}>
      <div className="text">{getT("components.iPanelV2Ad")}</div>
    </div>
  )
}
