import { useState, useContext, useRef, useEffect } from "react"
import { LoginContext } from "contexts/loginContext"
import Login from "./Login"
import Pin from "./Pin"
import Register from "./Register"
import ButtonSimple from "components/Buttons/ButtonSimple"
import { useLocation, useNavigate } from "react-router-dom"
import useMultilang from "intl/useMultilang"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/modalContext"
import IPanelV2Popup from "components/IPanelV2Popup"

function LoginScreen() {
  const { loggedIn } = useContext(LoginContext)
  const [screenContent, setScreenContent] = useState("login")
  const { pathname } = useLocation()
  const pathArray = pathname.split("/")
  const navigate = useNavigate()
  const { getT, language, setSelectedLanguage } = useMultilang()
  const langRef = useRef(null)
  const { addModal, popModal } = useContext(ModalContext)
  const timerRef = useRef(null)
  const loggedInRef = useRef(false)

  useEffect(() => {
    loggedInRef.current = loggedIn
  }, [loggedIn])

  const handleSelectLanguage = (lang) => {
    setSelectedLanguage(lang)
    popModal()
  }

  useEffect(() => {
    iPanelV2PopupTiming()
    return () => {
      clearTimeout(timerRef.current)
    }
    // eslint-disable-next-line
  }, [])

  const iPanelV2PopupTiming = () => {
    if (timerRef.current == null) {
      timerRef.current = setTimeout(() => {
        openIPanelV2Modal()
      }, 1500)
    }
  }

  const openIPanelV2Modal = () => {
    if (!loggedInRef.current && pathArray[1] !== "external") {
      addModal(
        <Modal key={uuidv4()} onClickLayout={popModal}>
          <IPanelV2Popup />
        </Modal>
      )
    }
  }

  const openLanguageModal = () => {
    addModal(
      <Modal key={uuidv4()} className="InputFieldMultiSelect-modal" onClickLayout={popModal} parentRef={langRef}>
        <div className="flag-container hu" onClick={() => handleSelectLanguage("hu")}>
          <img src="https://cdn.okeoke.io/global/flags/hu.svg" alt="huflag" />
        </div>
        <div className="flag-container en" onClick={() => handleSelectLanguage("en")}>
          <img src="https://cdn.okeoke.io/global/flags/gb.svg" alt="enflag" />
        </div>
      </Modal>
    )
  }

  if (loggedIn || pathArray[1] === "external") return null

  return (
    <div className="login-screen hidden appear">
      <div
        className="left-side"
        style={{
          backgroundImage: "url('https://cdn.okeoke.io/ipanel/log_in_background.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {/* <Tilt>
          <img src="https://cdn.okeoke.io/ipanel/card_1654185128.png" alt="card"/>
        </Tilt> */}
      </div>
      <div className="right-side">
        <div className="language-selector" ref={langRef} onClick={openLanguageModal}>
          {language === "en" && (
            <div className="flag-container en">
              <img src="https://cdn.okeoke.io/global/flags/gb.svg" alt="enflag" />
            </div>
          )}
          {language === "hu" && (
            <div className="flag-container hu">
              <img src="https://cdn.okeoke.io/global/flags/hu.svg" alt="huflag" />
            </div>
          )}
        </div>

        <div className="text text-large">iPanel</div>
        <div className="text text-normal">Login</div>
        {pathArray[1] !== "registration" && (
          <>
            {screenContent === "login" && <Login setScreenContent={setScreenContent} />}
            {screenContent === "register" && <Register setScreenContent={setScreenContent} />}
            {screenContent === "pin" && <Pin setScreenContent={setScreenContent} />}
            {screenContent === "login" && (
              <div
                className="registration text text-center mt-2"
                onClick={() => {
                  setScreenContent("register")
                }}
              >
                <span className="clickable">{getT("global.login.signUpHere")}</span>
              </div>
            )}
            {screenContent === "checkEmail" && (
              <div className="check-email text text-center font-extraLarge mt-2 pt-2">
                {getT("global.login.confirmationEmailSent")}
                <br />
                <ButtonSimple
                  className="btn-vip-color btn-big mt-2"
                  text={getT("global.login.login")}
                  onClick={() => {
                    setScreenContent("login")
                  }}
                />
              </div>
            )}
          </>
        )}
        {pathArray[1] === "registration" && pathArray[2] === "success" && (
          <>
            <div className="text text-center font-extraLarge mt-2 pt-2">{getT("global.login.success")}</div>
            <div className="text-center">
              <ButtonSimple
                className="btn-vip-color btn-big mt-2"
                text={getT("global.login.login")}
                onClick={() => {
                  navigate("/")
                }}
              />
            </div>
          </>
        )}
        {pathArray[1] === "registration" && pathArray[2] === "failed" && (
          <>
            <div className="text text-center font-extraLarge mt-2 pt-2">{getT("global.login.somethingWentWrong")}</div>
            <div className="text-center">
              <ButtonSimple
                className="btn-vip-color btn-big mt-2"
                text={getT("global.login.back")}
                onClick={() => {
                  navigate("/")
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export default LoginScreen
