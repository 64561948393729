import React, { useState, useEffect, useRef, useContext } from "react";
import InputMask from "react-input-mask";
import LoadSvg from "components/LoadSvg-v3";
import { ModalContext } from "contexts/modalContext";
import MultiLang from "./MultiLang";
import Modal from "components/Modal/Modal";
import { v4 as uuidv4 } from "uuid";

function TextField(props) {
  const {
    addRef,
    name,
    type,
    value,
    onClick,
    onChange,
    onBlur,
    onPaste,
    className,
    fullWidth,
    disabled,
    label,
    helperText,
    onKeyDown,
    error,
    mask,
    helperText2,
    error2,
    svgName,
    svgPos,
    multiLang,
    min,
    max,
    step,
    maxLength
  } = props;

  const [shrinkLabel, setShrinkLabel] = useState(false);
  const [focused, setFocused] = useState(false);
  const { addModal, popModal } = useContext(ModalContext);
  const multilangDataRef = useRef(null)

  useEffect(() => {
    if(type === "textarea") setShrinkLabel(true)
  }, [type])

  useEffect(() => {
    if(type !== "textarea") {
      focused
        ? setShrinkLabel(true)
        : setShrinkLabel(value != null && value !== "");
    }
  }, [value, focused, type]);

  const handleChange = (e) => {
    let data = e;
    if (multiLang) {
      data = {
        target: {
          name: e.target.name,
          value: { ...value, hu: e.target.value },
        },
      };
    }

    if (typeof onChange === "function") onChange(data);
  };

  const handleFocus = () => {
    if(type !== "textarea") {
      setShrinkLabel(true);
    }
    setFocused(true);
  };

  const handleKeyDown = (e) => {
    if(typeof onKeyDown === "function"){
        onKeyDown(e)
      }
   }

  const handleBlur = (e) => {
    if (type !== "textarea" && value === "") setShrinkLabel(false);
    let data = e;
    if (multiLang) {
      data = {
        target: {
          name: e.target.name,
          value: { ...value, hu: e.target.value },
        },
      };
    }
    if (typeof onBlur === "function") onBlur(data);
    setFocused(false);
  };

  const handleMultilangBlur = (e) => {
    onChange(e)
    multilangDataRef.current = e
  }

  const handleClick = (e) => {
    if (typeof onClick === "function") onClick(e);
  };

  const handlePaste = (e) => {
    if (typeof onPaste === "function") onPaste(e);
  };

  const handleClose = () => {
    if(typeof onBlur === "function") onBlur(multilangDataRef.current)
  }

  const ref = useRef(null);

  const rootClasses = `${className != null ? className : ""} ${
    fullWidth != null ? "fullWidth" : ""
  } ${disabled ? "disabled" : ""}`;

  const loadMultiLangModal = () => {
    addModal(
      <Modal
        key={uuidv4()}
        className="InputFieldMultiSelect-modal"
        onClickLayout={popModal}
        parentRef={ref}
        closeCallback={handleClose}
      >
        <MultiLang name={name} value={value} handleBlur={handleMultilangBlur} />
      </Modal>
    );
  };

  return (
    <>
      <div
        className={`textField-root ${rootClasses} ${focused ? "focused" : ""} ${
          svgName != null
            ? svgPos === "start"
              ? "with-icon icon-start"
              : "with-icon icon-end"
            : "no-icon"
        }`}
        onClick={handleClick}
      >
        {label != null && (
          <label className={`textField-label ${shrinkLabel ? "shrink" : ""}`}>
            {label}
          </label>
        )}
        <div ref={ref} className="textField-base">
          {svgName != null && svgPos === "start" && (
            <div className="icon-container">
              <LoadSvg name={svgName} />
            </div>
          )}
          {type !== "textarea" && <>
            {mask == null && (
              <input
                name={name}
                type={type}
                className="textField-input"
                value={multiLang ? value?.hu : value}
                onChange={handleChange}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                disabled={disabled}
                onPaste={handlePaste}
                ref={addRef}
                min={min}
                max={max}
                step={step}
                maxLength={maxLength}
              />
            )}
            {mask != null && (
              <InputMask
                mask={mask}
                name={name}
                type={type}
                className="textField-input"
                value={multiLang ? value?.hu || "" : value}
                onChange={handleChange}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                disabled={disabled}
                onPaste={handlePaste}
                ref={addRef}
                min={min}
                max={max}
                step={step}
                maxLength={maxLength}
              />
            )}
          </>}
          {type === "textarea" && <>
            <textarea
              name={name}
              className="textField-input-area"
              onChange={handleChange}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              disabled={disabled}
              onPaste={handlePaste}
              ref={addRef}
              value={multiLang ? value?.hu : value}
            />
          </>}
          {svgName != null && (svgPos == null || svgPos === "end") && (
            <div className="icon-container">
              <LoadSvg name={svgName} />
            </div>
          )}
          {multiLang && (
            <div
              className="btn btn-transparent"
              onClick={() => {
                loadMultiLangModal();
              }}
            >
              <LoadSvg name="global" />
            </div>
          )}
        </div>
        {helperText != null && (
          <p
            className={`textField-helperText ${error ? "helperTextError" : ""}`}
          >
            {helperText}
          </p>
        )}
        {helperText2 != null && helperText2 !== "" && (
          <p
            className={`textField-helperText ${
              error2 ? "helperTextError" : ""
            }`}
          >
            {helperText2}
          </p>
        )}
      </div>
    </>
  );
}

export default TextField;
