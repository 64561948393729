import { useRef } from "react"
import useMultilang from "intl/useMultilang"
import LoadSvg from "components/LoadSvg-v3"
import "./iPanelV2Popup.scss"

export default function IPanelV2Popup() {
  const confettiTargetRef = useRef(null)
  const forwardLinkWasClickedRef = useRef(false)
  const { getT } = useMultilang()
  const confetti = require("canvas-confetti")

  const handleV2onClick = () => {
    if (!forwardLinkWasClickedRef.current) {
      forwardLinkWasClickedRef.current = true
      let cordinates = confettiTargetRef.current?.getBoundingClientRect()
      confetti.default({
        particleCount: 100,
        startVelocity: 25,
        spread: 125,
        zIndex: 1001,
        origin: { x: cordinates.x / window.innerWidth + cordinates.width / 2 / window.innerWidth, y: cordinates.y / window.innerHeight },
      })
    }
  }

  return (
    <div className="ipanel-v2-popup-root">
      <div className="header">
        <div className="text">{getT("global.login.Iv2Header")}</div>
      </div>
      <div className="content">
        <div className="desc">{getT("global.login.Iv2Text")}</div>
        <a ref={confettiTargetRef} className="button-card" href={"https://barsoft.hu/home"} target="_self" onClick={handleV2onClick}>
          <div className="text">{getT("global.login.Iv2Redirect")}</div>
        </a>
        <div className="appdownload-text font-extraLarge mb-05">{getT("global.login.Iv2DownloadApps")}</div>
        <div className="app-icons">
          <a className="button-card bubble" href={"https://apps.apple.com/hu/app/barsoft-ipanel/id6468272616"} target="_blank" rel="noreferrer">
            <LoadSvg name={"apple"} />
          </a>
          <a className="button-card bubble" href={"https://play.google.com/store/apps/details?id=hu.barsoft.ipanel"} target="_blank" rel="noreferrer">
            <LoadSvg name={"android"} />
          </a>
        </div>
      </div>
    </div>
  )
}
